import React, {useContext} from 'react';
import { GlobalContext } from '../../data/GlobalContext';
import './formContent.css';

function FormContent(props) {
    const dbData = useContext(GlobalContext);
    return (
        <form-contact
            to={dbData.dbPrincipal.emails[0].email}
            services={dbData.dbServices.slice(0, 6).map((item, service) => {
                return item.name;
            })}
            server="https://paginasserver.herokuapp.com/email"
            img={dbData.dbPrincipal.logo}
            emailcolor="1"
        ></form-contact>
    );
}

export default FormContent;