import React, { useState, useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor"



function CounterWhite(props) {
    const dbData = useContext(GlobalContext);

    const [focus, setFocus] = useState(false)

    const counterblock = [
        {
            icon: 'fal fa-hard-hat',
            count: `${dbData.dbPrincipal.exprYears}`,
            title: 'Of Experience',
            plusIcon:'Years'
        },
        {
            icon: 'fal fa-history',
            count: '100',
            title: 'On Time',
            plusIcon:'%'
        },
        {
            icon: 'fal fa-user-hard-hat',
            count: '100',
            title: 'Professionals',
            plusIcon:'%'
        },
        {
            icon: 'fal fa-map-marked-alt',
            count: `${
                dbData.dbPrincipal.miles !== ' ' ? dbData.dbPrincipal.miles : '100'
            }`,
            title: `
                ${
                    dbData.dbPrincipal.miles !== ' ' ? `Around ${dbData.dbPrincipal.location[0].address}` : `State of ${dbData.dbPrincipal.location[0].address}`
                }
            `,
            plusIcon:`${dbData.dbPrincipal.miles !== ' ' ? 'Miles' : '% Of the'}`
        },
    ]
    return (
        <section className="fact-section grey-bg">
            <div className="container">
                <div className="fact-boxes row justify-content-between align-items-center">
                    {counterblock.map((item, i) => (
                        <div key={i} className="col-lg-3 col-6">
                            <div className="fact-box text-center mb-40">
                                <div className="icon">
                                    <i className={item.icon}></i>
                                </div>
                                <CountUp start={focus ? 0 : null} end={parseInt(item.count)} duration={5} redraw={true}>
                                    {({ countUpRef }) => (
                                        <div>
                                            <span className="counter" ref={countUpRef} />
                                            <VisibilitySensor
                                                onChange={isVisible => {
                                                    if (isVisible) {
                                                        setFocus(true)
                                                    }
                                                }}
                                            >
                                                <span className="plus-icon" style={{padding:'0 10px'}}>{item.plusIcon}</span>
                                            </VisibilitySensor>
                                        </div>
                                    )}
                                </CountUp>
                                <p className="title">{item.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CounterWhite;