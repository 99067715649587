import React from 'react'
import Slider from 'react-slick'

import quote from '../../../assets/img/icons/quote.png'
import men from '../../../assets/img/icons/men.png'
import women from '../../../assets/img/icons/women.png'


const testimonialslide = [
    {
        img: men,
        titulo: 'Install Drywall',
        name: 'Anthony C.',
        post: '8/22/2021 - Reno, NV',
        comment: 'Fast, Friendly, Competent, Value...........'
    },
    {
        img: women,
        titulo: 'Repair Drywall',
        name: 'Kelly M.',
        post: '6/19/2021 - Carson City, NV',
        comment: 'They did an amazing job!'
    },
    {
        img: men,
        titulo: 'Install Drywall',
        name: 'Jim Z.',
        post: '3/12/2021 - Sparks, NV',
        comment: 'Very efficient and clean.'
    },
    {
        img: men,
        titulo: 'Repair Drywall',
        name: 'Daren M',
        post: '2/3/2021',
        comment: 'I would recommend them.'
    },
]
const SampleNextArrow = (props) => {
    const { onClick } = props
    return (
        <button className="slick-arrow next-arrow" onClick={onClick}><i className="fal fa-long-arrow-right" /></button>
    )
}

const SamplePrevArrow = (props) => {
    const { onClick } = props
    return (
        <button className="slick-arrow prev-arrow" onClick={onClick}><i className="fal fa-long-arrow-left" /></button>
    )
}

function Testimonials(props) {
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        arrows: true,
        fade: false,
        dots: true,
        dotsClass: "testimonial-dots slick-dots slick-thumb",
        swipe: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [{
            breakpoint: 576,
            settings: {
                arrows: false
            }
        }],

    }


    return (
        <section className="testimonial-section-two section-gap">
            <div className="container">
                <div className="row justify-content-center no-gutters">
                    <div className="col-lg-9">
                        <div className="section-title both-border text-center mb-80">
                            <span className="title-tag">Our Reviews</span>
                            <h2 className="title">Improve Your Home <br /> With Fresh Paint</h2>
                        </div>
                        <Slider className="testimonial-items" id="testimonialSliderTwo" {...settings}>
                            {testimonialslide.map((item, i) => (
                                <div key={i} className="testimonial-item">
                                    <div className="author-img">
                                        <img src={item.img} alt="" />
                                    </div>
                                    <div className="content">
                                        <h3>{item.titulo}</h3>
                                        <p>
                                            <span className="quote-top">
                                                <i className="fas fa-quote-left" />
                                            </span>
                                            {item.comment}
                                            <span className="quote-bottom">
                                                <i className="fas fa-quote-right" />
                                            </span>
                                        </p>
                                    </div>
                                    <div className="author-name">
                                        <h4>{item.name}</h4>
                                        <span>{item.post}</span>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="testimonial-quote-icon">
                <img src={quote} alt="quote" />
            </div>
        </section>
    );
}

export default Testimonials;