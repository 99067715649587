import React, { Fragment, useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../data/GlobalContext'
import classes from './header.module.css'

import { Link } from 'react-router-dom';
import Navmenu from './Navmenu';
import { GiHamburgerMenu } from 'react-icons/gi'

const Headertwo = () => {
    const dbData = useContext(GlobalContext);

    const [offset, setOffset] = useState(0);

    const [hasMounted, setHasMounted] = useState(false);

    const hasMountedOpen = () => setHasMounted(!hasMounted);


    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const stickyheader = offset > 100 ? 'sticky-on' : '';

    return (
        <Fragment>
            <header className={
                stickyheader ?
                    `header-two sticky-header sticky-on`
                    :
                    `header-two sticky-header `
            }>
                {/* Header Topbar */}
                <div className="header-topbar">
                    <div className="container-fluid container-1600">
                        <div className="header-topbar-inner d-md-flex align-items-center justify-content-between">
                            {/* Contact Info */}
                            <ul className="contact-info">
                                <li>
                                    <a href={`tel:+1${dbData.dbPrincipal.phones[0].phone}`}>
                                        <i class="far fa-phone"></i>
                                        {dbData.dbPrincipal.phones[0].phone}
                                    </a>
                                </li>
                                <li>
                                    <a href={`mailto:${dbData.dbPrincipal.emails[0].email}`}>
                                        <i className="far fa-envelope-open" />
                                        {dbData.dbPrincipal.emails[0].email}
                                    </a>
                                </li>
                                <li>
                                    <i className="far fa-map-marker-alt" />
                                    {dbData.dbPrincipal.location[0].address}
                                </li>
                            </ul>
                            {/* Social Links */}
                            <ul className="social-links">
                                {
                                    dbData.dbSocialMedia.redes.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <a href={item.url} target="_blank">
                                                    <i className={item.icon} />
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Header Menu  */}
                <div className="header-nav sticky-nav">
                    <div className={classes.headerNavContent}>
                        <div className={`nav-container row`}>
                            {/* Site Logo */}
                            <div className="site-logo col-lg-3 col-md-3 col-9 py-1">
                                <Link to="/">
                                    <img src={dbData.dbPrincipal.logo} alt="Logo" style={stickyheader ? { width: '180px' } : { width: '100%' }} />
                                </Link>
                            </div>
                            {/* Mneu Items */}
                            <div className="d-none d-lg-flex align-items-center justify-content-end col-lg-9 col-md-9 col-3">
                                <div className="menu-items">
                                    <Navmenu />
                                </div>
                                {/* Navbar Extra  */}
                                <div className="navbar-extra d-lg-block d-flex align-items-center ml-5">
                                    {/* Navbtn */}
                                    <div className={`navbar-btn nav-push-item`}>
                                        <Link to="/contact" className="main-btn main-btn-3 text-capitalize">free estimate</Link>
                                    </div>
                                </div>
                            </div>
                            {/* Navbar Toggler */}
                            <div className='navbar-toggler active d-flex d-lg-none' onClick={hasMountedOpen} >
                                <GiHamburgerMenu color='white' />
                            </div>
                            {/* Mobile Menu */}
                            <div className={
                                hasMounted ? `offcanvas-wrapper show-offcanvas ${classes.navbarToggler__content}` : 'offcanvas-wrapper'
                            }>
                                <div className="offcanvas-overlay" onClick={hasMountedOpen} />
                                <div className="offcanvas-inner">
                                    <div className="offcanvas-menu">
                                        <div className="offcanvas-header">
                                            <div className="offcanvas-close" onClick={hasMountedOpen}>
                                                <i className="fal fa-times" />
                                            </div>
                                            <div className="site-logo">
                                                <img src={dbData.dbPrincipal.logo} alt="Logo" />
                                            </div>
                                        </div>
                                        <div className="offcanvas-body">
                                            <Navmenu />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>
        </Fragment>
    )
}

export default Headertwo