import React, { Fragment, useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import { Container } from 'react-bootstrap';
import MetaTags from 'react-meta-tags'
// import Header from '../layouts/Header'
import Header from '../layouts/Headertwo'
import Footer from '../layouts/Footer'
import Breadcrumbs from '../layouts/Breadcrumbs'
import AboutContent from '../sections/About/AboutContent'
import ConuterBlack from '../layouts/ConuterBlack';
import HeroImages from '../layouts/HeroImages';
import ValuesContent from '../layouts/ValuesContent';


// import dbData from '../data/db';


const About = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Fragment>
            <MetaTags>
                <title>{dbData.dbPrincipal.name} | About Us</title>
                <meta
                    name={dbData.dbSlogan[0].slogan}
                    content={dbData.dbHome[0].text}
                />
                <link rel="icon" type="image/png" sizes="32x32" href={dbData.dbPrincipal.favicon} />
            </MetaTags>
            <Header />
            <Breadcrumbs pagename='About Us' bgImage={dbData.stock[16]} />
            <Container>
                <AboutContent />
                <ConuterBlack />
            </Container>
            <HeroImages bgImage={dbData.stock[9]} />
            <ValuesContent />
            <Footer />
        </Fragment>
    );
}

export default About;