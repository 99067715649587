import React, { Component, Fragment } from 'react'
import GalleryContent from '../gallery/GalleryContent';

class Content extends Component {
    render() {

        return (
            <Fragment>
                <h1 className='py-5 text-center text-capitalize'>Our Recent Projects</h1>
                <GalleryContent />
                <div className="py-5"></div>
            </Fragment>
        );
    }
}

export default Content;